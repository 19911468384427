<template>
  <navbar></navbar>
  <div>
  
    <VehicleDetailsCard></VehicleDetailsCard>
  
    <div class="new-main-tile">
      <section>
        <div class=""></div>
      </section>
  
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import VehicleDetailsCard from "../modules/VehicleDetailsCard.vue";

export default {
  components: {
    VehicleDetailsCard,
    navbar,
  },

  data() {
    return {
      auctionByAucName: "",
    };
  },
  mounted() {
    // console.log(this.$route.params.url_key);

    window.scrollTo(0, 0);
    // let urlkey = this.$route.params.url_key;
    // if (urlkey) {
    //   this.getAuctionDetails(urlkey);
    //}
  },
};
</script>
