<template>
  <div>
    <div class="">
      <div v-if="loading" class="text-center ;" style="margin-top: 20%">
        <p>Loading .....</p>
      </div>
      <div v-else class="sp-area">
        <div class="">

          <div class="sp-content" style="margin: 0px 20px">
            <div class="sp-nav" style="position: relative">
              <div class="row">
                <div class="col-lg-8">
                  <div class="sp-img_area" v-if="vehImageArry">

                
                    <Carousel
                      v-if="vehImageArry.length !== 0"
                      id="gallery"
                      :items-to-show="1"
                      :wrap-around="false"
                      v-model="currentSlide"
                    >
                  
                      <Slide
                        v-for="(slide, index) in vehImageArry"
                        :key="index"
                      >
                        <div class="carousel__item">
                          <img :src="path + slide.imageurl" />
                        </div>
                      </Slide>
                      <template #addons>
                        <Navigation />
                      </template>
                    </Carousel>

                    <Carousel
                      v-if="vehImageArry.length !== 0"
                      id="thumbnails"
                        :items-to-show="4"
                            :wrap-around="true"
                            v-model="currentSlide"
                          ref="carousel"
                            :navigation-enabled="true"
                    >
                      <Slide
                        v-for="(slide, index) in vehImageArry"
                        :key="index"
                      >
                        <div class="carousel__item">
                          <img
                            :src="path + slide.imageurl"
                            @click="slideTo(index)"
                          />
                        </div>
                      </Slide>
                        <template #addons>
                        <Navigation />
                      </template> 
                    </Carousel>


                  </div>
                  <div v-if="autionDetails.vehdescription != null">
                    <p class="vech-detail-desc">
                      {{ autionDetails.vehdescription }}.
                    </p>
                  </div>

                  <div class="" style="margin-top: 20px">
                    <section>
                      <div
                        class="main-separator"
                        v-if="autionDetails.length !== 0"
                      >
                        <div>
                          <h3>VIN Information</h3>
                        </div>
                        <div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="flex-sm-l">
                                <span class="one-s">VIN</span>
                                <span class="two-s">{{
                                  autionDetails.vin
                                }}</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="flex-sm-l">
                                <span class="one-s">Year</span>
                                <span class="two-s">{{
                                  autionDetails.manYear
                                }}</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="flex-sm-l">
                                <span class="one-s">Make</span>
                                <span class="two-s">{{
                                  autionDetails.brandName
                                }}</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="flex-sm-l">
                                <span class="one-s">Model</span>
                                <span class="two-s">{{
                                  autionDetails.modelName
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="" v-if="autionDetails">
                    <div>
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabs-1"
                            role="tab"
                            >Vehicle Information</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                            >Vehicle Conditions</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-3"
                            role="tab"
                            >Features</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-4"
                            role="tab"
                            >Technical Specifications</a
                          >
                        </li>
                      </ul>
                      <!-- Tab panes -->
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="tabs-1"
                          role="tabpanel"
                        >
                          <div v-if="autionDetails">
                            <div class="tablerounededCorner">
                              <table class="table table-striped roundedTable">
                                <tr v-if="vehicleInfo && vehicleInfo[0]">
                                  <td>Trim</td>
                                  <td>{{ vehicleInfo[0].trim }}</td>
                                </tr>
                                <tr v-if="vehicleInfo && vehicleInfo[0]">
                                  <td>Drive train</td>
                                  <td>{{ vehicleInfo[0].driveType }}</td>
                                </tr>
                                <tr v-if="vehicleInfo && vehicleInfo[0]">
                                  <td>Body type</td>
                                  <td>{{ vehicleInfo[0].segment[0] }}</td>
                                </tr>
                                <tr v-if="vehicleInfo && vehicleInfo[0]">
                                  <td>No Of Doors</td>
                                  <td>{{ vehicleInfo[0].doors }}</td>
                                </tr>
                                <tr>
                                  <td>Mileage</td>
                                  <td>{{ autionDetails.kmClocked }}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!-- vehicle condition -->
                        <div class="tab-pane" id="tabs-2" role="tabpanel">
                          <div
                            class="main-separator"
                            v-if="vehicleConditions.length !== 0"
                          >
                            <div class="row">
                              <div
                                class="col-md-4"
                                v-for="(condition, index) in vehicleConditions"
                                :key="index"
                              >
                                <h5>{{ condition.name }}</h5>
                                <h6>{{ condition.condition }}</h6>
                                <img
                                  class="img-fluid"
                                  :src="path + condition.image"
                                  :alt="
                                    'Vehicle Condition Image ' + (index + 1)
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div v-else><NotFound></NotFound></div>
                        </div>
          
                        <div class="tab-pane" id="tabs-3" role="tabpanel">
                          <div v-if="features">
                            <div class="tablerounededCorner">
                              <table class="table table-striped roundedTable">
                                <template v-if="features.length !== 0">
                                  <tr
                                    v-for="(feature, index) in features"
                                    :key="index"
                                  >
                                    <template
                                      v-if="feature.description === 'Wheels'"
                                    >
                                      <td>Wheels</td>
                                      <td>{{ feature.name }}</td>
                                    </template>
                                    <template
                                      v-if="feature.description === 'Tires'"
                                    >
                                      <td>Tires</td>
                                      <td>{{ feature.name }}</td>
                                    </template>
                                    <template
                                      v-if="
                                        feature.description === 'Door locks'
                                      "
                                    >
                                      <td>Door locks</td>
                                      <td>{{ feature.name }}</td>
                                    </template>
                                    <template
                                      v-if="
                                        feature.description === 'All-in-one key'
                                      "
                                    >
                                      <td>All-in-one key</td>
                                      <td>{{ feature.name }}</td>
                                    </template>
                                    <template
                                      v-if="
                                        feature.description ===
                                        'Seating capacity'
                                      "
                                    >
                                      <td>Seating capacity</td>
                                      <td>{{ feature.name }}</td>
                                    </template>
                                  </tr>
                                  <tr
                                    class="text-center"
                                    v-if="checkMatchesForFeature"
                                  >
                                    <td colspan="2">Not Availabe</td>
                                  </tr>
                                </template>
                              </table>
                            </div>
                          </div>
                          <div v-else>
                            <NotFound></NotFound>
                          </div>
                        </div>
                    
                        <div class="tab-pane" id="tabs-4" role="tabpanel">
                          <div v-if="autionDetails">
                            <div class="tablerounededCorner">
                              <table class="table table-striped roundedTable">
                                <template v-if="techSpac!=undefined && techSpac.length !== 0"> 
                                  <tr
                                    v-for="(spech, index) in techSpac"
                                    :key="index"
                                  >
                                    <template v-if="spech.description === 'cc'">
                                      <td>cc</td>
                                      <td>{{ spech.name }}</td>
                                    </template>
                                    <template
                                      v-if="
                                        spech.description === 'Engine Cylinders'
                                      "
                                    >
                                      <td>Engine Cylinders</td>
                                      <td>{{ spech.name }}</td>
                                    </template>
                                  </tr>
                                  <tr
                                    v-if="checkMatchesForSpec"
                                    class="text-center"
                                  >
                                    <td colspan="2"><NotFound></NotFound></td>
                                  </tr>
                                </template>
                              </table>
                            </div>
                          </div>
                          <div v-else>
                            <NotFound></NotFound>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div class="col-lg-4">
                  <div class="details-right-wrap">
                    <div class="sp-heading">
                      <h5 class="vech-detai-title" v-if="autionDetails">
                        <h5>
                          {{ autionDetails.manYear }}
                          {{ autionDetails.brandName }}
                        </h5>

                        <h1 class="auct-info">
                          {{ autionDetails.modelName }}
                        </h1>
                        <h1
                          class="vech-info-"
                          v-if="vehicleInfo && vehicleInfo[0]"
                        >
                          {{ vehicleInfo[0].styleDescription }}
                        </h1>
                      </h5>
                    </div>
                    <hr />
                    <div class="bid-info-wrap" v-if="autionDetails">
                      <div>
                        <div class="show-vin-details">
                          <a> #{{ autionDetails.vin }}</a>
                        </div>

                        <h4 class="detai-p-m-amount">
                          {{
                            autionDetails.latestBidAmount == null
                              ? $n(0, "currency", language)
                              : $n(
                                  autionDetails.latestBidAmount,
                                  "currency",
                                  language
                                )
                          }}
                        </h4>
                        <div class="bid-wrap" v-if="timer !== ''">
                          <p class="bid-count-wrap" v-if=" autionDetails.bidzCount > 0">
                            Bids:
                            {{
                              autionDetails.bidzCount == null
                                ? 0
                                : autionDetails.bidzCount
                            }}
                          </p>
                          <p class="bid-break" v-if=" autionDetails.bidzCount > 0">|</p>
                          <p class="bid-amount-wrap">
                            Increment
                            <span class="current-bid-mount">
                              :&nbsp;{{
                                autionDetails.aucMinBid == null
                                  ? $n(0, "currency", language)
                                  : $n(
                                      autionDetails.aucMinBid,
                                      "currency",
                                      language
                                    )
                              }}</span
                            >
                          </p>
                        </div>
                        <div class="cust-pos-t-r cust-timer-detai" v-if="timer">
                          <div class="">
                            <CountDown :deadline="timer"></CountDown>
                          </div>
                        </div>
                      </div>
                      <button
                        v-if="timer"
                        style="background-color:#BF0A30;s"
                        :disabled="Buttondisabled"
                        class="tile-bid-place-bid"
                        tabindex="-1"
                        @click="showModal(autionDetails)"
                      >
                        <i class="fa fa-gavel" aria-hidden="true"></i> PLACE BID
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     <!-- modal -->
     <div class="overlay-bid-btn" v-if="openModel && userinfo">
            <button class="close-bid-btn bid-popup-close" @click="closeModal()">
              x
            </button>
            <h4>Bid Information</h4>           
            <p>Latest Bid Amount : {{ $n(amount, "currency", language) }}</p>
            <div class="auct-bid-pop-left-wrap">
              <div class="auct-bid-pop-left">
                <button class="btn btn-secondary" :class="{ 'buttonDisabled': isSubmitButtonDisabled }"
                  :disabled="isSubmitButtonDisabled" @click="decreaseBidAmount()"><span>-</span></button>
                <div class="auct-bid-pop-left-inner">
                  <input type="number" v-model="bidAmount" class="form-control" style="height: 37px" />
                </div>
                <button class="btn btn-secondary" @click="increaseBidAmount()"><span>+</span></button>
              </div>
              <button class="bid-info-sub" :class="{ 'buttonDisabled': isSubmitButtonDisabled }" @click="SubmitBid()"
                :disabled="isSubmitButtonDisabled">
                Submit
              </button>
            </div>
          </div>
    </div>
  </div>
</template>
<script>

import { defineComponent } from "vue";
import moment from "moment";
import CountDown from "../modules/CountDown.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import userService from "@/services/user.service";
import Config from "@/config.js";
import NotFound from "../modules/NotFound.vue";

export default defineComponent({
  name: "VehicleDetailsCard",
  components: {
    Carousel,
    Slide,
    CountDown,
    // Pagination,
    Navigation,
    NotFound,
  },

  data() {
    return {
      visible: false,
      index: 0,
      path: Config.BASE_URL_IMG,
      imgs123: "",
      autionDetails: [],
      timer: "",
      imageArray: [],
      openModel: false,
      amount: "",
      AuctionsDetails: "",
      userinfo: "",
      custId: "",
      bidAmount: "",
      Buttondisabled: false,
      selectedAuction: "",
      message: "",
      BidTimeOut: Config.bidModalTimeout,
      language: Config.BASE_LANG,
      currentSlide: 0,
      vehImageArry: [],
      vehicleInfo: [],
      features: [],
      techSpac: [],
      vehicleCondName: [],
      vehicleCond: [],
      vehicleConditions: [],
      vinDetails: [],
      VINVDecodeInfo: [],
      loading:true,
      minIceament: "",
    };
  },
  beforeMount() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo) {
      if (typeof this.userinfo.customerId != "undefined") {
        this.custId = this.userinfo.customerId;
        let urlkey = this.$route.params.url_key;
        if (!this.custId || !urlkey) {
          this.$router.go(-1);
        } else {
          this.Buttondisabled = false;
          if (urlkey) {
            this.getAuctionDetails(urlkey);
          }
        }
      } else {
        this.$router.go(-1);
      }
    }
  },

  computed: {
    checkMatchesForSpec() {
      let isTruthy = true;
      for (const spech of this.techSpac) {
        if (
          spech.description === "cc" ||
          spech.description === "Engine Cylinders"
        ) {
          isTruthy = false;
          break;
        } else {
          isTruthy = true;
        }
      }
      return isTruthy;
    },
    checkMatchesForFeature() {
      let isTruthy = true;
      for (const feature of this.features) {
        if (
          feature.description === "Wheels" ||
          feature.description === "Tires" ||
          feature.description === "Door locks" ||
          feature.description === "All-in-one key" ||
          feature.description === "Seating capacity"
        ) {
          isTruthy = false;
          break;
        } else {
          isTruthy = true;
        }
      }
      return isTruthy;
    },
    isSubmitButtonDisabled() {
      return this.bidAmount <= this.amount;
    },
  },

  methods: {
    showModal(e) {
      this.openModel = !this.openModel;
      this.amount = e.latestBidAmount;
      this.minIceament = e.aucMinBid;
      this.bidAmount = e.latestBidAmount + this.minIceament;
      this.selectedAuction = e;
    },
    closeModal() {
      var self = this;
      setTimeout(function () {
        self.hideModal();
      }, this.BidTimeOut);
    },
    hideModal() {
      this.openModel = false;
      this.message = "";
    },
    isMultipleOfHundred(number) {
  return number % this.minIceament === 0;
},
// minIceament

decreaseBidAmount() {

if (this.bidAmount >= this.amount) {
  this.bidAmount -= this.minIceament;
}

},
increaseBidAmount() {
this.bidAmount += this.minIceament;
},
    SubmitBid() {
      if (this.custId && this.bidAmount !== "" && this.bidAmount !== null) {
        let cusid = this.custId;
        let bidamount = parseInt(this.bidAmount);
        if(!this.isMultipleOfHundred(this.bidAmount)){
            this.$toast.warning("Please enter a multiple of 100", {
              position: "top",
              duration: 3000,
              queue: true
            });
          }else {
        userService
          .addbid(this.selectedAuction, cusid, bidamount)
          .then((response) => {
            this.message = response.data.Message;
            this.closeModal();
            let urlkey = this.$route.params.url_key;

            this.getAuctionDetails(urlkey);
            this.$toast.success(" Suceesfully added bid", {
              position: "top",
              duration: 3000,
              queue: true
            });
          })
          .catch((error) => {
            this.message =
              (error.response && error.response.data.Message) ||
              error.message ||
              error.toString();
            this.message = "";
            this.$toast.error(error.response.data.Message, {
              position: "top",
              duration: 3000,
              queue: true
            });
          });}
      } else {
        this.$toast.error("Please enter a valid bid amount", {
          position: "top",
          duration: 3000,
          queue: true
        });
      }
    },
    showImg(imgs123) {
      this.imgs123 = imgs123;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    loadimage(img) {
      this.imgs123 = img;
    },
    slideTo(val) {
      this.currentSlide = val;
    },
    getAuctionDetails(key) {
      userService
        .getCustomerauctionbyname(key)
        .then((response) => {      

          this.autionDetails = response.data.Data[0];
         // console.log(this.autionDetails.length);

          if( this.autionDetails ){
         
          this.loading = false;

          this.imgs123 = this.autionDetails.vehImage1;

          if (
            moment(this.autionDetails.aucExtDate).format(
              "YYYY-MM-DD HH:mm:ss"
            ) > moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
          ) {
            this.timer = this.autionDetails.aucExtDate;
          } else {
            this.timer = "";
          }

          let image1 = null;
          let image2 = null;
          let image3 = null;
          let image4 = null;
          let image5 = null;
          let image6 = null;
          let image7 = null;
          let image8 = null;
          let image9 = null;
          let image10 = null;
          let image11 = null;
          let image12 = null;
          let image13 = null;
          let image14 = null;
          let image15 = null;

          if (typeof this.autionDetails.vehImage1 != "undefined") {
            image1 = this.autionDetails.vehImage1;
          } //else{image1  = ""};
          if (typeof this.autionDetails.vehImage2 != "undefined") {
            image2 = this.autionDetails.vehImage2;
          } //else{image2  = ""};
          if (typeof this.autionDetails.vehImage3 != "undefined") {
            image3 = this.autionDetails.vehImage3;
          } //else{image3  = ""};
          if (typeof this.autionDetails.vehImage4 != "undefined") {
            image4 = this.autionDetails.vehImage4;
          } //else{image4  = ""};
          if (typeof this.autionDetails.vehImage5 != "undefined") {
            image5 = this.autionDetails.vehImage5;
          } //else{image5  = ""};
          if (typeof this.autionDetails.vehImage6 != "undefined") {
            image6 = this.autionDetails.vehImage6;
          } //else{image6  = ""};
          if (typeof this.autionDetails.vehImage7 != "undefined") {
            image7 = this.autionDetails.vehImage7;
          } //else{image7  = ""};
          if (typeof this.autionDetails.vehImage8 != "undefined") {
            image8 = this.autionDetails.vehImage8;
          } //else{image8  = ""};
          if (typeof this.autionDetails.vehImage9 != "undefined") {
            image9 = this.autionDetails.vehImage9;
          } //else{image9  = ""};
          if (typeof this.autionDetails.vehImage10 != "undefined") {
            image10 = this.autionDetails.vehImage10;
          } //else{image10 = ""};
          if (typeof this.autionDetails.vehImage11 != "undefined") {
            image11 = this.autionDetails.vehImage11;
          } //else{image11 = ""};
          if (typeof this.autionDetails.vehImage12 != "undefined") {
            image12 = this.autionDetails.vehImage12;
          } //else{image12 = ""};
          if (typeof this.autionDetails.vehImage13 != "undefined") {
            image13 = this.autionDetails.vehImage13;
          } //else{image13 = ""};
          if (typeof this.autionDetails.vehImage14 != "undefined") {
            image14 = this.autionDetails.vehImage14;
          } //else{image14 = ""};
          if (typeof this.autionDetails.vehImage15 != "undefined") {
            image15 = this.autionDetails.vehImage15;
          } //else{image15 = ""};

          let imageArry = [
            { imageurl: image1 },
            { imageurl: image2 },
            { imageurl: image3 },
            { imageurl: image4 },
            { imageurl: image5 },
            { imageurl: image6 },
            { imageurl: image7 },
            { imageurl: image8 },
            { imageurl: image9 },
            { imageurl: image10 },
            { imageurl: image11 },
            { imageurl: image12 },
            { imageurl: image13 },
            { imageurl: image14 },
            { imageurl: image15 },
          ];

          this.vehImageArry = imageArry.filter(function (el) {
            return el.imageurl != null;
          });

          let auctionInfo = response.data.Data[0];

          if (auctionInfo.vinDetails !== null) {
            this.vinDetails = JSON.parse(auctionInfo.vinDetails);

            this.vehicleInfo = this.vinDetails.vehicles;
            // console.log(this.vehicleInfo);

            // Features

            this.features = this.vinDetails.features;
            //  console.log("Features : ", this.features);

            //Technical Specifications

            this.techSpac = this.vinDetails.techSpecs;
          }

          // vehicle condition
          if (auctionInfo.vehicleConditionName !== null) {
            // Split the vehicle condition, name, and image strings into arrays
            const conditions = auctionInfo.vehicleCondition.split(",");
            const names = auctionInfo.vehicleConditionName.split(",");

            for (let i = 1; i <= 9; i++) {
              const imgKey = `vehicleConditionImg${i}`;
              const image = auctionInfo[imgKey];

              if (image) {
                this.vehicleConditions.push({
                  name: names[i - 1],
                  condition: conditions[i - 1],
                  image: image,
                });
              }
            }
          }
        }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
});
</script>
<style>
.detailsBtn:hover {
  background-color: #4caf50; /* Green */
  color: white;
}
.countdownlefttop {
  /* background-color: white; */
  display: flex;
  padding: 5px;
  height: 35px;
  border-radius: 3px;
  float: right;
  background-color: transparent;
}
.digit {
  margin: 0px;
}
/* .countdownlefttop {
  padding: 0px;
  height: 0px;
} */
.carousel__next {
  top: 50%;
  right: 16px;
  transform: translate(50%, -50%);
}
.carousel__prev {
  top: 50%;
  left: 16px;
  transform: translate(-50%, -50%);
}
.bid-wrap,
.bid-count-wrap,
.bid-break,
.bid-amount-wrap,
.current-bid-mount {
  display: inline;
}
.buttonDisabled {
  opacity: 0.2;
}
</style>
